import React, { useEffect, useState } from "react";

import { Container, Spinner } from "react-bootstrap";
import AttributeCard from "./AttributeCard/AttributeCard";

const SolutionAttributes = () => {
  const [attributes, setAttributes] = useState([]);

  useEffect(() => {
    fetch("https://o2nb-global-server.onrender.com/solutionAttribute")
      .then((res) => res.json())
      .then((data) => setAttributes(data));
  }, []);

  return (
    <Container
      style={{
        marginTop: "50px",
        marginBottom: "50px",
      }}
    >
      <h1
        className="fw-bold"
        style={{ fontSize: "40px", color: "#007D8A", marginBottom: "30px" }}
      >
        Attributes of Our Integrated Solution for Clean Water
      </h1>
      <section className="my-5 pb-5 d-flex justify-content-around flex-wrap overflow-hidden">
        {attributes?.length ? (
          attributes.map((attribute) => (
            <AttributeCard key={attribute.id} attribute={attribute} />
          ))
        ) : (
          <Spinner animation="border" variant="success" />
        )}
      </section>
    </Container>
  );
};

export default SolutionAttributes;
