import React from "react";
import { Button, Card } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const MilestoneCardTZ = ({ milestone, handleClickTz }) => {
  return (
    <Card className="my-3" style={{ width: "24rem" }}>
      <Card.Img variant="top" src={milestone?.thumbnail} />
      <Card.Body>
        <Card.Title>{milestone?.title}</Card.Title>
        <NavLink to={`/tz/${milestone?._id}`}>
          <Button variant="outline-success">Details</Button>
        </NavLink>
      </Card.Body>
    </Card>
  );
};

export default MilestoneCardTZ;
