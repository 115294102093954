import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import introBanner from "../../../image/about/introBanner.webp";

const Introduction = () => {
    return (
        <Container fluid className="px-0 py-5">
      <img src={introBanner} className="w-100" alt="O2&B Korea" />
      <h5 className="py-3 px-5 fs-5 text-end text-secondary text-capitalize">
        O<sub>2</sub>&B assures the prospect of a clean world. Genuine wealth
        results from the affluent and tranquil nature.
      </h5>
      <Container>
        <Row className="my-5">
          <Col
            xs={12}
            sm={12}
            md={{ offset: 2, span: 8 }}
            lg={{ offset: 0, span: 6 }}
            className="d-flex align-items-center"
          >
            <h1 className="fs-1 text-start" style={{ color: "#022a58" }}>
              The best partner should make a success of your business...
            </h1>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={{ offset: 2, span: 8 }}
            lg={{ offset: 0, span: 6 }}
          >
            <h6 className="py-3 px-5 lh-lg fs-6 text-start text-secondary">
              The right answer is the investment for the good environment to
              establish the better environment facilities. 02&B pledges to
              become a sincere company satisfying this mission. We, 02&B, have
              the capability for engineering design and construction operation
              on the basis of the excellent R&D performance and engineering
              technology. The control system, operation software and process
              treatment facilities which are developed for ourselves, are
              certified as the national new excellent technologies and we have
              many outstanding patents involved in them. We will do our best to
              make Thailand's future more graceful with our sincere integrity.
            </h6>
          </Col>
        </Row>
      </Container>
    </Container>
    );
};

export default Introduction;