import React from "react";
import Importance from "../Importance/Importance";
import Partner from "../Partner/Partner";
import Products from "../Products/Products";
import ProjectActivities from "../ProjectActivities/ProjectActivities";

const BangladeshHome = () => {
  return (
    <div>
      <Importance />
      <Products />
      <Partner />
      <ProjectActivities />
    </div>
  );
};

export default BangladeshHome;
