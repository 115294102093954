import React from "react";
import { Card, Container } from "react-bootstrap";
import "./TanzaniaBanner.css";

const TanzaniaBanner = () => {
  return (
    <Container
      fluid
      style={{ paddingTop: "830px" }}
      className="bannerContainerTz position-relative"
    >
      <Container className="position-relative">
        <Card
          style={{ width: "32rem", background: "#779d24", padding: "20px" }}
          className="position-absolute bottom-0 d-none d-md-block "
        >
          <Card.Body className="text-start">
            <div className="d-flex justify-content-start">
              <Card.Title className="pb-4 fs-1 pe-4 fw-bold text-light">
                Tanzania
              </Card.Title>
              <img
                src="https://i.ibb.co/CMmvqnf/flag-Tanzania.webp"
                alt="Tanzanian Flag"
                style={{ width: "50px", height: "50px" }}
              />
            </div>
            <Card.Text className="text-light fw-bold">
              Tanzania is an East African country known for its vast wilderness
              areas.Tanzania is facing a huge water crisis. The ongoing
              freshwater shortage harms sanitation and may result in public
              health crises such as diarrhea, cholera, and dysentery which can
              cost people's lives. Looking forward solve these water crises{" "}
              <span className="fw-bold fs-5">O2&B </span>
              is running many extensive activities in Tanzania. Some mentionable
              projects are Fluorine processing facilities in Arusha, Dar es
              Salaam Bottling business, Provision of IBS fluorine proc
              purification system, etc.
            </Card.Text>
            {/* <Button
          variant="light"
          className="fw-bold"
          onClick={() => {
            navigate("/about");
          }}
        >
          Know More
        </Button> */}
          </Card.Body>
        </Card>
        <Card
          style={{ width: "20rem", background: "#779d24", padding: "20px" }}
          className="position-absolute bottom-0 .d-block d-md-none "
        >
          <Card.Body className="text-start">
            <Card.Title className="pb-4 fs-3 fw-bold text-light">
              We are O<sub>2</sub>
              <span className="fs-4">&</span>B, the environmental engineers.
            </Card.Title>
            <Card.Text className="text-light fw-bold">
              Tanzania is an East African country known for its vast wilderness
              areas.Tanzania is facing a huge water crisis. The ongoing
              freshwater shortage harms sanitation and may result in public
              health crises such as diarrhea, cholera, and dysentery which can
              cost people's lives. Looking forward solve these water crises{" "}
              <span className="fw-bold">O2&B</span>
              is running many extensive activities in Tanzania. Some mentionable
              projects are Fluorine processing facilities in Arusha, Dar es
              Salaam Bottling business, Provision of IBS fluorine proc
              purification system, etc.
            </Card.Text>
          </Card.Body>
          {/* <Button
            variant="light"
            className="fw-bold"
            onClick={() => {
              navigate("/about");
            }}
          >
            Know More
          </Button> */}
        </Card>
      </Container>
    </Container>
  );
};

export default TanzaniaBanner;
