import React from "react";
import { Container } from "react-bootstrap";
import patent1 from "../../../image/about/patent_1.webp";
import patent2 from "../../../image/about/patent_2.webp";
import patent3 from "../../../image/about/patent_3.webp";
import patent4 from "../../../image/about/patent_4.webp";
import patent5 from "../../../image/about/patent_5.webp";
import patent6 from "../../../image/about/patent_6.webp";
import patent7 from "../../../image/about/patent_7.webp";
import patent8 from "../../../image/about/patent_8.webp";
import patent9 from "../../../image/about/patent_9.webp";

const Patents = () => {
  return (
    <Container>
      <h1 className="fs-1 text-start" style={{ color: "#022a58" }}>
        Patents
      </h1>
      <h6 className="py-3 px-5 lh-lg fs-6 text-start text-secondary">
        - Tap water treatment : No. 0373513, No. 0373511, No. 0373512, No.
        0636265, No. 0728739, No. 0774881, No. 0883035, No. 0899763, No. 0919919
        <br />- Sewer treatment : No. 0430034, No. 0458563, No. 1002191
      </h6>
      <section className="d-flex justify-content-around flex-wrap">
        <img src={patent1} className="p-1 my-2 mx-2 border border-3" style={{borderRadius: "15px", boxShadow: "0 0 5px #000000"}} alt="patent" />
        <img src={patent2} className="p-1 my-2 mx-2 border border-3" style={{borderRadius: "15px", boxShadow: "0 0 5px #000000"}} alt="patent" />
        <img src={patent3} className="p-1 my-2 mx-2 border border-3" style={{borderRadius: "15px", boxShadow: "0 0 5px #000000"}} alt="patent" />
        <img src={patent4} className="p-1 my-2 mx-2 border border-3" style={{borderRadius: "15px", boxShadow: "0 0 5px #000000"}} alt="patent" />
        <img src={patent5} className="p-1 my-2 mx-2 border border-3" style={{borderRadius: "15px", boxShadow: "0 0 5px #000000"}} alt="patent" />
        <img src={patent6} className="p-1 my-2 mx-2 border border-3" style={{borderRadius: "15px", boxShadow: "0 0 5px #000000"}} alt="patent" />
        <img src={patent7} className="p-1 my-2 mx-2 border border-3" style={{borderRadius: "15px", boxShadow: "0 0 5px #000000"}} alt="patent" />
        <img src={patent8} className="p-1 my-2 mx-2 border border-3" style={{borderRadius: "15px", boxShadow: "0 0 5px #000000"}} alt="patent" />
        <img src={patent9} className="p-1 my-2 mx-2 border border-3" style={{borderRadius: "15px", boxShadow: "0 0 5px #000000"}} alt="patent" />
      </section>
    </Container>
  );
};

export default Patents;
