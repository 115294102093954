import React from "react";
import { Card } from "react-bootstrap";
import "./AttributeCard.css";

const AttributeCard = ({ attribute }) => {
  return (
    <Card className="my-3 attributeCard" style={{ width: "24rem" }}>
      <Card.Body>
        <Card.Title className="py-3" style={{fontSize: "50px"}}><i className={attribute.iconClass}></i></Card.Title>
        <Card.Text className="fs-5">{attribute.title}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default AttributeCard;
