import { useEffect } from "react";
import { useState } from "react";

const useMilestonesTZ = () => {
  const [milestones, setMilestones] = useState([]);
  useEffect(() => {
    fetch("https://o2nb-global-server.onrender.com/prjMilestone")
      .then((res) => res.json())
      .then((data) => setMilestones(data));
  }, []);
  return { milestones, setMilestones };
};

export default useMilestonesTZ;
