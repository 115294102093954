import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const TzAdminNav = () => {
  return (
    <Navbar bg="light" expand="lg">
      <Container
        style={{ borderBottom: "5px solid #009edb", marginBottom: "15px" }}
      >
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto">
            <NavLink
              to="/eTZofhAdminDB"
              className="text-primary p-3 fs-5 text-decoration-none menuItem"
            >
              <h5 className="p-2 rounded-3">Manage</h5>
            </NavLink>
            <NavLink
              to="/eTZofhAdminDBuPloAD"
              className="text-primary p-3 fs-5 text-decoration-none menuItem"
            >
              <h5 className="p-2 rounded-3">Upload Information</h5>
            </NavLink>
            {/* <NavLink
              to="/eTZofhAdminDBrcvMsg"
              className="text-primary p-3 fs-5 text-decoration-none menuItem"
            >
              <h5 className="p-2 rounded-3">
                Received Messages{" "}
                <span className="ms-2 badge bg-warning rounded-pill">14</span>
              </h5>
            </NavLink> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default TzAdminNav;
