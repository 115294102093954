import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import PartnerCard from "./PartnerCard";

const Partners = () => {
  const [partners, setPartners] = useState([]);
  useEffect(() => {
    fetch("./partners.json")
      .then((res) => res.json())
      .then((data) => setPartners(data));
  }, []);
  return (
    <Container style={{ marginTop: "50px", marginBottom: "50px" }}>
      <h1
        className="fw-bold"
        style={{ fontSize: "40px", color: "#007D8A", marginBottom: "10px" }}
      >
        Our Partners
      </h1>
      <h2 style={{ fontSize: "25px", color: "#007D8A", marginBottom: "30px" }}>
        We collaborate with so many prosperous organizations throughout the
        whole world. <br /> And we are rapidly growing.
      </h2>
      <section className="d-flex justify-content-around flex-wrap">
        {partners.map((partner) => (
          <PartnerCard key={partner.partnerId} partner={partner} />
        ))}
      </section>
    </Container>
  );
};

export default Partners;
