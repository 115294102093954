import React, { useEffect, useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import GoalCards from "./GoalCards/GoalCards";

const Goal = () => {
  const [goals, setGoals] = useState([]);

  useEffect(() => {
    fetch("https://o2nb-global-server.onrender.com/goal")
      .then((res) => res.json())
      .then((data) => setGoals(data));
  }, []);

  return (
    <div>
      <Container
        fluid
        className="px-0 position-relative"
        style={{
          background: "#1cabe2",
          marginTop: "150px",
          marginBottom: "50px",
        }}
      >
        <Container className="py-4">
          <div className="py-5 my-5 text-light">
            <h1 className="fs-1 lh-lg">Our Goals</h1>
            <h3 className="text-capitalize">
              O<sub>2</sub>
              <span className="fs-4">&</span>B wants to take the Availability of
              pure drinking water to that stage, where people no matter how
              remote area they lived in, don't have to worry for a single moment
              about collecting pure drinking water.
            </h3>
          </div>
        </Container>
        <div
          className="position-absolute"
          style={{
            width: "60px",
            height: "60px",
            background: "#1cabe2",
            bottom: "-15px",
            left: "48%",
            transform: "rotate(45deg)",
          }}
        ></div>
      </Container>
      <Container className="d-flex flex-wrap justify-content-around">
        {goals?.length ? (
          goals.map((goal) => <GoalCards key={goal.id} goal={goal} />)
        ) : (
          <Spinner animation="border" variant="success" />
        )}
      </Container>
    </div>
  );
};

export default Goal;
