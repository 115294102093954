import React from "react";
import { Container } from "react-bootstrap";
import useMilestonesTZ from "../../../../hook/useMilestonesTZ";
import TzAdminNav from "../TzShared/TzAdminNav/TzAdminNav";
import TzAdminManageCard from "./TzAdminManageCard/TzAdminManageCard";

const TzAdminManage = () => {
  const { milestones, setMilestones } = useMilestonesTZ();
  const handleDeleteTz = (id) => {
    const proceed = window.confirm("Are you sure?");
    if (proceed) {
      fetch(`https://o2nb-global-server.onrender.com/prjMilestone/${id}`, {
        method: "DELETE",
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.deletedCount) {
            alert("One post deleted!");
            const remainingMilestones = milestones.filter(
              (milestone) => milestone._id !== id
            );
            setMilestones(remainingMilestones);
          }
        });
    }
  };
  return (
    <>
      <TzAdminNav />
      <Container className="d-flex justify-content-around flex-wrap ">
        {milestones.map((milestone) => (
          <TzAdminManageCard
            key={milestone._id}
            milestone={milestone}
            handleDeleteTz={handleDeleteTz}
          />
        ))}
      </Container>
    </>
  );
};

export default TzAdminManage;
