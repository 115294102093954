import React, { useEffect, useState } from "react";
import { Card, Container } from "react-bootstrap";
import adminLogo from "../../../image/about/logo.webp";
import AdminCard from "./AdminCard";

const AdministrativeStructure = () => {
  const [adminSections, setAdminSections] = useState([]);
  useEffect(() => {
    fetch("./adminparts.json")
      .then((res) => res.json())
      .then((data) => setAdminSections(data));
  }, []);
  return (
    <Container>
      <h1 className="fs-1 pb-5" style={{ color: "#022a58" }}>
        Administrative Structure
      </h1>
      <section className="w-100 d-flex justify-content-center">
        <Card style={{ width: "24rem" }} className="mb-2">
          <Card.Img variant="top" className="p-4" src={adminLogo} />
          <Card.Body>
            <h3>CHIEF EXECUTIVE OFFICER</h3>
          </Card.Body>
        </Card>
      </section>
      <section className="d-flex justify-content-around flex-wrap">
        {adminSections.map((adminSection) => (
          <AdminCard key={adminSection.id} adminSection={adminSection} />
        ))}
      </section>
    </Container>
  );
};

export default AdministrativeStructure;
