import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Importance.css";

const Importance = () => {
  return (
    <Container fluid className="px-0 my-5 overflow-hidden">
      <Row className="">
        <Col xs={12} sm={12} md={12} lg={6} className="px-0">
          <img
            src="https://i.ibb.co/x7hFKCG/importance1.webp"
            className="w-100"
            alt="pitcher"
          />
        </Col>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={6}
          className="p-5 d-flex align-items-center"
          style={{ background: "#ffc107" }}
        >
          <div>
            <h1 className="fs-1 text-start text-light fw-bold d-block d-lg-none d-xl-none d-xxl-block">
              Importance of "Drinking Water Purification" In Bangladesh.
            </h1>
            <h3 className="fs-3 text-start text-light fw-bold d-none d-lg-block d-xxl-none">
              Importance of "Drinking Water Purification" In Bangladesh.
            </h3>

            <article
              className="text-start py-3 text-light fw-bold  article2"
              style={{ color: "#4e4e4e" }}
            >
              <em>
                <h5>
                  [ Bangladesh still has the largest proportion of people
                  exposed to arsenic contamination. ]
                </h5>
              </em>

              <p>
                Bangladesh, to the east of India on the Bay of Bengal, is a
                South Asian country marked by lush greenery and many waterways.
                Bangladesh is a riverine country. According to the Bangladesh
                Water development board, about 230 rivers currently flow in
                Bangladesh. A total of 57 international rivers flow through
                Bangladesh. But this is a matter of great regret that even
                though Bangladesh is so riverine but a huge portion of
                Bangladesh's population doesn't have easy access to clean
                drinkable water. Two out of five households, that is 38.3
                percent of the population, in Bangladesh drink water from
                sources already contaminated with disease-causing bacteria and
                viruses.But due to poor hygiene practices in households, the
                number of people drinking water with microbial contamination
                jumps to 99 million. Contaminated water can transmit diseases
                such diarrhoea, cholera, dysentery, typhoid, and polio.
                Contaminated drinking water is estimated to cause 485 000
                diarrhoeal deaths each year.
              </p>
            </article>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Importance;
