import React from "react";
import AdministrativeStructure from "../AdministrativeStructure/AdministrativeStructure";
import CeoMsg from "../CeoMsg/CeoMsg";
import Introduction from "../Introduction/Introduction";
import MajorBA from "../MajorBA/MajorBA";
import NewTech from "../NewTech/NewTech";
import Patents from "../Patents/Patents";
import ProjectMap from "../ProjectMap/ProjectMap";

const About = () => {
  return (
    <div>
      <Introduction />
      <CeoMsg />
      <MajorBA />
      <NewTech />
      <Patents />
      <ProjectMap />
      <AdministrativeStructure />
    </div>
  );
};

export default About;
