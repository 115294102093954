import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Envsg from "../../../image/environment.svg";
import eSocg from "../../../image/undraw_co_workers_re_1i6i.svg";
import esGov from "../../../image/undraw_business_deal_re_up4u.svg";

const Qualities = () => {
  return (
    <Container fluid>
      <div className="text-start py-5 ps-5" style={{ background: "#e8eff8" }}>
        <h1
          style={{
            fontSize: "35px",
            color: "#007D8A",
            fontWeight: 300,
          }}
        >
          WE ARE <span style={{ fontWeight: 500 }}>ESG</span> INVESTORS
        </h1>
        <h1 className="lh-lg" style={{ fontSize: "30px", fontWeight: 300 }}>
          E S G (Environmental, social, and Governance)
        </h1>
        <p style={{ fontWeight: 300 }}>
          orgainizational financial interests that include sustainability as a
          cornerstone, represent corporate asset stewarted that focus on
          corporate financial interests.
        </p>
      </div>
      <Container
        style={{
          marginTop: "80px",
          marginBottom: "50px",
        }}
      >
        <h1
          className="fw-bold"
          style={{ fontSize: "50px", color: "#007D8A", marginBottom: "30px" }}
        >
          Our Policy
        </h1>

        <Row className="pt-5 pb-3">
          <Col xs={12} sm={12} md={6} lg={5} xl={4}>
            <img
              src={Envsg}
              className="w-100 my-2"
              alt="Environment Friendly"
            />
          </Col>
          <Col
            xs={12}
            sm={12}
            md={6}
            lg={7}
            xl={8}
            className="text-start pt-2 px-4 textBox d-flex align-items-center"
            style={{ background: "#779d24", borderRadius: "50px" }}
          >
            <article className="text-light">
              <h2 className="fs-2 pb-3">✓ Green Technology</h2>
              <h5 className="fs-6 lh-lg">
                All solutions of O2&B are environment friendly. We can proudly
                say no products of O2&B will cause harm to the environment. We
                design our technological solutions based on nature-compatible
                terms. We use solar energy in most cases for powering our
                machines. We don't use any fuel that causes harm to nature. All
                technological solutions of O2&B produce very fewer waste
                materials and no waste materials containing plastic or
                polyethylene. We are producing many amazing technologies to make
                pure drinking water available for all. Our purifiers use the
                best technologies to purify undrinkable water.{" "}
              </h5>
            </article>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="py-5">
          <Col xs={12} sm={12} md={6} lg={5} xl={4}>
            <img
              src={eSocg}
              className="w-100 my-2"
              alt="Environment Friendly"
            />
          </Col>
          <Col
            xs={12}
            sm={12}
            md={6}
            lg={7}
            xl={8}
            className="text-start pt-2 px-4 textBox d-flex align-items-center"
            style={{ background: "#01aeef", borderRadius: "50px" }}
          >
            <article className="text-light">
              <h2 className="fs-2 pb-3">✓ Protecting all rights</h2>
              <h5 className="fs-6 lh-lg">
                We take care of all social factors in our organization. We have
                several branches throughout the world. We protect human rights
                in all branches of O2&B. We always take care of the welfare of
                our employees. We ensure equal opportunities for all. We are
                very protective of health measures. In the pandemic situation,
                we ensure that all of our workers are maintaining sanitization.
                We provide all safety equipment to our engineers and field
                workers to avoid any kind of harm. We are against child labor.
                We believe that we are responsible for all our products.
                Consumer satisfaction is our motto.
              </h5>
            </article>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="py-5">
          <Col xs={12} sm={12} md={6} lg={5} xl={4}>
            <img
              src={esGov}
              className="w-100 my-2"
              alt="Environment Friendly"
            />
          </Col>
          <Col
            xs={12}
            sm={12}
            md={6}
            lg={7}
            xl={8}
            className="text-start pt-2 px-4 textBox d-flex align-items-center"
            style={{ background: "#c77836", borderRadius: "50px" }}
          >
            <article className="text-light">
              <h2 className="fs-2 pb-3">✓ Integrity with partners</h2>
              <h5 className="fs-6 lh-lg">
                O2&B has business ethics. We never break our ethics. We believe
                in honesty. We do always do ethical transactions. O2&B is
                totally against corruption. We do not allow any kind of
                unethical procedures inside of our organization and we don't
                keep in business relation with any unethical organization. We
                strongly believe in shareholder democracy. We are always ready
                to protect our shareholders' rights.
              </h5>
            </article>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Qualities;
