import React from "react";
import { Button, Card, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./Banner.css";

const Banner = () => {
  let navigate = useNavigate();

  return (
    <Container
      fluid
      style={{ paddingTop: "830px" }}
      className="bannerContainer position-relative"
    >
      <Container className="position-relative">
        <Card
          style={{ width: "32rem", background: "#00aeef", padding: "20px" }}
          className="position-absolute bottom-0 d-none d-md-block "
        >
          <Card.Body className="text-start">
            <Card.Title className="pb-4 fs-3 fw-bold text-light">
              We are O<sub>2</sub>
              <span className="fs-4">&</span>B, the environmental engineers.
            </Card.Title>
            <Card.Text className="text-light fw-bold">
              02&B is an advanced design company that shows a new paradigm of
              total design that suggests environment design, landscape
              architectural design, and working design via investment for human
              resource development, a realistic design based on field
              experience, and continuous self-development and innovation.
            </Card.Text>
            <Button
              variant="light"
              className="fw-bold"
              onClick={() => {
                navigate("/about");
              }}
            >
              Know More
            </Button>
          </Card.Body>
        </Card>
        <Card
          style={{ width: "20rem", background: "#00aeef", padding: "20px" }}
          className="position-absolute bottom-0 .d-block d-md-none "
        >
          <Card.Body className="text-start">
            <Card.Title className="pb-4 fs-3 fw-bold text-light">
              We are O<sub>2</sub>
              <span className="fs-4">&</span>B, the environmental engineers.
            </Card.Title>
            <Card.Text className="text-light fw-bold">
              02&B is an advanced design company that shows a new paradigm of
              total design that suggests environment design, landscape
              architectural design, and working design via investment for human
              resource development, a realistic design based on field
              experience, and continuous self-development and innovation.
            </Card.Text>
          </Card.Body>
          <Button
            variant="light"
            className="fw-bold"
            onClick={() => {
              navigate("/about");
            }}
          >
            Know More
          </Button>
        </Card>
      </Container>
    </Container>
  );
};

export default Banner;
