import React, { useRef, useState } from "react";
import { Alert } from "react-bootstrap";
import emailjs from "emailjs-com";
import { useForm } from "react-hook-form";

const Mail = () => {
  const [sent, setSent] = useState(false);
  const form = useRef();
  const { register, handleSubmit, reset } = useForm();

  const onSubmit = (data) => {
    emailjs
      .sendForm(
        "service_xknl2cn",
        "template_bnv3ywf",
        form.current,
        "-tRGs4wLMA1wkE6FG"
      )
      .then(
        (result) => {
          setSent(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
    reset();
  };

  return (
    <div>
      <div className="w-100">
        {sent && (
          <Alert variant="success" className="fs-5">
            O<sub>2</sub>
            <span className="fs-6">&</span>B Co. Ltd. got your email
            successfully. Thank You!
          </Alert>
        )}
        <form
          ref={form}
          onSubmit={handleSubmit(onSubmit)}
          className="d-flex flex-column pt-2"
        >
          <input
            {...register("name", { required: true, maxLength: 200 })}
            placeholder="Your Name"
            className="border-0 border-bottom border-3 border-info fs-6 fw-bold p-3 my-3 w-100 text-light"
            style={{ background: "#172227", borderRadius: "10px" }}
          />
          <input
            {...register("subject", { required: true, maxLength: 1000 })}
            placeholder="Subject"
            className="border-0 border-bottom border-3 border-info fs-6 fw-bold p-3 my-3 text-light"
            style={{ background: "#172227", borderRadius: "10px" }}
          />
          <input
            {...register("email", { required: true, maxLength: 200 })}
            placeholder="Your Email"
            className="border-0 border-bottom border-3 fs-6 fw-bold border-info p-3 my-3 text-light"
            style={{ background: "#172227", borderRadius: "10px" }}
          />
          <textarea
            {...register("message", { required: true })}
            placeholder="Message"
            className="border-0 border-bottom border-3 fs-6 fw-bold border-info p-3 my-3 text-light"
            style={{ background: "#172227", borderRadius: "10px" }}
          />
          <input
            type="submit"
            value="Sent"
            className="w-25 mx-auto text-white border-0 fs-5 rounded mt-3 py-2"
            style={{ background: "#00aeef" }}
          />
        </form>
      </div>
    </div>
  );
};

export default Mail;
