import React from "react";
import { Card } from "react-bootstrap";

const GoalCards = ({ goal }) => {
  return (
    <div>
      <Card className="my-5" style={{ width: "19rem" }}>
        <Card.Img variant="top" src={goal.goalImg} />
        <Card.Body className="text-light" style={{ background: goal.bgClr }}>
          <Card.Title>{goal.goalTitle}</Card.Title>
          <Card.Text className="text-start">{goal.goalDetails}</Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
};

export default GoalCards;
