import React from "react";
import { Card } from "react-bootstrap";

const AdminCard = ({adminSection}) => {
  return (
    <Card style={{ width: "12rem" }} className="my-3">
      <Card.Body className="text-start">
        <h5 className="text-center">{adminSection.title}</h5>
        <p>{adminSection.resp1}</p>
        <p>{adminSection.resp2}</p>
        <p>{adminSection.resp3}</p>
        <p>{adminSection.resp4}</p>
      </Card.Body>
    </Card>
  );
};

export default AdminCard;
