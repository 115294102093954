import React from "react";
import { Container } from "react-bootstrap";
import TzAdminUpInfo from "./TzAdminUpInfo/TzAdminUpInfo";
import TzAdminNav from "./TzShared/TzAdminNav/TzAdminNav";

const TanzaniaAdminDashboard = () => {
  return (
    <Container fluid>
      <TzAdminNav />
      <TzAdminUpInfo />
    </Container>
  );
};

export default TanzaniaAdminDashboard;
