import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import o2nbBDlogo from "../../../../image/site_logo.webp";
import Mail from "../Mail/Mail";
// import { HashLink } from "react-router-hash-link";

const Footer = () => {
  return (
    <Container
      fluid
      className="px-0 border-0 border-top border-3 pb-5 mt-5 overflow-hidden"
      style={{ background: "#273a43" }}
    >
      <Container>
        <Row className="border-0 border-bottom border-3 border-info">
          <Col xs={12} sm={12} md={12} lg={4} xl={4} className="px-0 py-5">
            <div
              style={{ background: "#172227", borderRadius: "20px" }}
              className="h-100 border border-5 border-info py-5 px-4 text-start"
            >
              <h3 className="text-light fw-bold">
                <span className="border-0 border-bottom border-3 border-info">
                  Contact
                </span>{" "}
                Us
              </h3>
              <h5
                className="fs-5 lh-lg pt-3"
                style={{ color: "rgb(207 195 195)" }}
              >
                In case of any query about our service please allow us to
                resolve your query. Your mail will be received by O2&B, within a
                short time we will get back to you. Get in touch with us. We
                will be very glad to hear from you.
              </h5>
              <h6 className="fs-6 lh-lg pt-3 fw-bold text-info d-none d-lg-block">
                <i className="fas fa-caret-right"></i> Mail Us{" "}
                <i className="fas fa-arrow-right"></i>
              </h6>
              <h6 className="fs-6 lh-lg pt-3 fw-bold text-info d-block d-lg-none">
                <i className="fas fa-caret-down"></i> Mail Us
                <i className="fas fa-arrow-down ps-2"></i>
              </h6>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={8} xl={8} className="px-0 py-5">
            <div className="px-5">
              <Mail />
            </div>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row className="text-light">
          <Col
            xs={12}
            sm={7}
            md={4}
            lg={3}
            className="d-flex align-items-center my-5"
          >
            <img src={o2nbBDlogo} className="w-100" alt="logo" />
          </Col>
          <Col
            xs={12}
            sm={12}
            md={6}
            lg={{ span: 4, offset: 1 }}
            className="text-start d-flex align-items-center my-5 fw-bold ps-4"
          >
            <div>
              {/* <h3 className="lh-lg" style={{ paddingLeft: "16px" }}>
                <span className="border-0 border-bottom border-3 border-info">
                  {t("footerLinkTitle1")}
                </span>{" "}
                {t("footerLinkTitle2")}
              </h3>
              <ul className="ps-0 d-flex justify-content-between">
                <div>
                  <li className="ps-3 py-2">
                    <HashLink
                      smooth
                      to="/background#bg"
                      className="fs-5 fw-light text-decoration-none"
                      style={{ color: "rgb(207 195 195)" }}
                    >
                      {t("footerLink1")}
                    </HashLink>
                  </li>
                  <li className="ps-3 py-2">
                    <HashLink
                      smooth
                      to="/about#abBan"
                      className="fs-5 fw-light text-decoration-none"
                      style={{ color: "rgb(207 195 195)" }}
                    >
                      {t("footerLink2")}
                    </HashLink>
                  </li>
                  <li className="ps-3 py-2">
                    <HashLink
                      smooth
                      to="/busArea#baBan"
                      className="fs-5 fw-light text-decoration-none"
                      style={{ color: "rgb(207 195 195)" }}
                    >
                      {t("footerLink3")}
                    </HashLink>
                  </li>
                  <li className="ps-3 py-2">
                    <HashLink
                      smooth
                      to="/implementation#pprj"
                      className="fs-5 fw-light text-decoration-none"
                      style={{ color: "rgb(207 195 195)" }}
                    >
                      {t("footerLink4")}
                    </HashLink>
                  </li>
                </div>
                <div>
                  <li className="ps-3 py-2">
                    <HashLink
                      smooth
                      to="/impArticle#impArt1"
                      className="fs-5 fw-light text-decoration-none"
                      style={{ color: "rgb(207 195 195)" }}
                    >
                      {t("footerLink5")}
                    </HashLink>
                  </li>
                  <li className="ps-3 py-2">
                    <HashLink
                      smooth
                      to="/services#srTech"
                      className="fs-5 fw-light text-decoration-none"
                      style={{ color: "rgb(207 195 195)" }}
                    >
                      {t("footerLink6")}
                    </HashLink>
                  </li>
                  <li className="ps-3 py-2">
                    <HashLink
                      smooth
                      to="/busArea#smSc"
                      className="fs-5 fw-light text-decoration-none"
                      style={{ color: "rgb(207 195 195)" }}
                    >
                      {t("footerLink7")}
                    </HashLink>
                  </li>
                </div>
              </ul> */}
            </div>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={{ span: 3, offset: 1 }}
            className="text-start my-5 "
          >
            <h3 className="lh-lg">
              <span className="border-0 border-bottom border-3 border-info">
                Connect
              </span>{" "}
              With Us
            </h3>
            <ul className="ps-0">
              <li className="lh-lg">
                <a
                  href="https://www.youtube.com/channel/UCajDZRdPORbe8mZDs-k20VQ"
                  className="fs-5 fw-light text-decoration-none"
                  style={{ color: "rgb(207 195 195)" }}
                  rel="noreferrer"
                  target="_blank"
                >
                  <div className="d-flex justify-content-start align-items-center fs-4">
                    <i
                      className="fab fa-youtube pe-5"
                      style={{ color: "#71b6d5" }}
                    ></i>
                    <span className="">Youtube</span>
                  </div>
                </a>
              </li>
              <li className="lh-lg">
                <a
                  href="https://www.facebook.com/"
                  className="fs-5 fw-light text-decoration-none"
                  style={{ color: "rgb(207 195 195)" }}
                  rel="noreferrer"
                  target="_blank"
                >
                  <div className="d-flex justify-content-start align-items-center fs-4">
                    <i
                      className="fab fa-facebook pe-5"
                      style={{ color: "#71b6d5" }}
                    ></i>
                    <span className="">Facebook</span>
                  </div>
                </a>
              </li>
              <li className="lh-lg">
                <a
                  href="https://www.linkedin.com/"
                  className="fs-5 fw-light text-decoration-none"
                  style={{ color: "rgb(207 195 195)" }}
                  rel="noreferrer"
                  target="_blank"
                >
                  <div className="d-flex justify-content-start align-items-center fs-4">
                    <i
                      className="fab fa-linkedin pe-5"
                      style={{ color: "#71b6d5" }}
                    ></i>
                    <span className="ps-1">Linkedin</span>
                  </div>
                </a>
              </li>
              <li className="lh-lg">
                <a
                  href="https://twitter.com/"
                  className="fs-5 fw-light text-decoration-none"
                  style={{ color: "rgb(207 195 195)" }}
                  rel="noreferrer"
                  target="_blank"
                >
                  <div className="d-flex justify-content-start align-items-center fs-4">
                    <i
                      className="fab fa-twitter pe-5"
                      style={{ color: "#71b6d5" }}
                    ></i>{" "}
                    <span className="ps-1">Twiter</span>
                  </div>
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Footer;
