import React from "react";
import { useForm } from "react-hook-form";
import { Container } from "react-bootstrap";

const TzAdminUpInfo = () => {
  const { register, handleSubmit, reset } = useForm();
  const onSubmit = (newInfo) => {
    fetch("https://o2nb-global-server.onrender.com/prjMilestone", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newInfo),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.insertedId) {
          window.alert("New information added");
          reset();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          placeholder="Headline"
          {...register("title", { required: true, maxLength: 300 })}
          className="fs-5 px-3 py-2 my-4 d-block w-100 border border-info border-3 rounded-3"
        />
        <input
          type="url"
          placeholder="Thumbnail"
          {...register("thumbnail", { required: true, maxLength: 1000 })}
          className="fs-5 px-3 py-2 my-4 d-block w-100 border border-info border-3 rounded-3"
        />
        <input
          placeholder="Details"
          {...register("details", { required: true, maxLength: 2000 })}
          className="fs-5 px-3 py-2 my-4 d-block w-100 border border-info border-3 rounded-3"
        />
        <input
          type="url"
          placeholder="Image 1"
          {...register("img1", { required: false, maxLength: 1000 })}
          className="fs-5 px-3 py-2 my-4 d-block w-100 border border-info border-3 rounded-3"
        />
        <input
          placeholder="Image 1 Subtitle"
          {...register("img1Subtitle", { required: false, maxLength: 100 })}
          className="fs-5 px-3 py-2 my-4 d-block w-100 border border-info border-3 rounded-3"
        />
        <input
          type="url"
          placeholder="Image 2"
          {...register("img2", { required: false, maxLength: 1000 })}
          className="fs-5 px-3 py-2 my-4 d-block w-100 border border-info border-3 rounded-3"
        />
        <input
          placeholder="Image 2 Subtitle"
          {...register("img2Subtitle", { required: false, maxLength: 100 })}
          className="fs-5 px-3 py-2 my-4 d-block w-100 border border-info border-3 rounded-3"
        />
        <input
          type="url"
          placeholder="Image 3"
          {...register("img3", { required: false, maxLength: 1000 })}
          className="fs-5 px-3 py-2 my-4 d-block w-100 border border-info border-3 rounded-3"
        />
        <input
          placeholder="Image 3 Subtitle"
          {...register("img3Subtitle", { required: false, maxLength: 100 })}
          className="fs-5 px-3 py-2 my-4 d-block w-100 border border-info border-3 rounded-3"
        />
        <input
          value="Post"
          className="px-3 py-2 fs-5 bg-info border-0 "
          type="submit"
        />
      </form>
    </Container>
  );
};

export default TzAdminUpInfo;
