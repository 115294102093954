import React from "react";
import { Container } from "react-bootstrap";
import VisibilitySensor from "react-visibility-sensor";
import CountUp from "react-countup";
import "./LiveData.css";

const LiveData = () => {
  return (
    <Container
      className="d-flex justify-content-around flex-wrap"
      style={{ marginTop: "100px" }}
    >
      <div className="mx-3">
        <div className="dataCircle d-flex justify-content-center align-items-center mx-auto">
          <div className="innerDataCircle d-flex justify-content-center align-items-center">
            <div
              className="fw-bold"
              style={{ fontSize: "50px", color: "#007D8A" }}
            >
              <VisibilitySensor partialVisibility offset={{ bottom: 20 }}>
                {({ isVisible }) => (
                  <div style={{ height: 1 }}>
                    {isVisible ? (
                      <CountUp start={0} end={11} delay={0}>
                        {({ countUpRef }) => (
                          <div>
                            <span ref={countUpRef} />
                          </div>
                        )}
                      </CountUp>
                    ) : null}
                  </div>
                )}
              </VisibilitySensor>
              <span style={{ color: "transparent" }}>1</span>
            </div>
          </div>
        </div>
        <p
          className="fs-4 fw-bold text-uppercase py-3"
          style={{ color: "#007D8A" }}
        >
          SUCCESSFUL MEGA PROJECTS
        </p>
      </div>

      <div className="mx-3">
        <div className="dataCircle d-flex justify-content-center align-items-center mx-auto">
          <div className="innerDataCircle d-flex justify-content-center align-items-center">
            <div
              className="fw-bold"
              style={{ fontSize: "39px", color: "#007D8A" }}
            >
              <VisibilitySensor partialVisibility offset={{ bottom: 20 }}>
                {({ isVisible }) => (
                  <div style={{ height: 1 }}>
                    {isVisible ? (
                      <CountUp start={0} end={1425168} delay={0}>
                        {({ countUpRef }) => (
                          <div>
                            <span ref={countUpRef} />
                          </div>
                        )}
                      </CountUp>
                    ) : null}
                  </div>
                )}
              </VisibilitySensor>
              <span style={{ color: "transparent" }}>1</span>
            </div>
          </div>
        </div>
        <p
          className="fs-4 fw-bold text-uppercase py-3"
          style={{ color: "#E0457B" }}
        >
          BENEFITED CONSUMERS
        </p>
      </div>

      <div className="mx-3">
        <div className="dataCircle d-flex justify-content-center align-items-center mx-auto">
          <div className="innerDataCircle d-flex justify-content-center align-items-center">
            <div
              className="fw-bold"
              style={{ fontSize: "50px", color: "#007D8A" }}
            >
              <VisibilitySensor partialVisibility offset={{ bottom: 20 }}>
                {({ isVisible }) => (
                  <div style={{ height: 1 }}>
                    {isVisible ? (
                      <CountUp start={0} end={5} delay={0}>
                        {({ countUpRef }) => (
                          <div>
                            <span ref={countUpRef} />
                          </div>
                        )}
                      </CountUp>
                    ) : null}
                  </div>
                )}
              </VisibilitySensor>
              <span style={{ color: "transparent" }}>1</span>
            </div>
          </div>
        </div>
        <p
          className="fs-4 fw-bold text-uppercase py-3"
          style={{ color: "#9E652E" }}
        >
          INTERNATIONAL ENTITIES
        </p>
      </div>
    </Container>
  );
};

export default LiveData;
