import React from "react";
import { Col, Container, Row, Carousel } from "react-bootstrap";
import "./HomeSDGslider.css";

const HomeSDGslider = () => {
  return (
    <Container
      fluid
      className="px-0 my-5"
      style={{ overflow: "hidden", paddingBottom: "50px" }}
    >
      <Row>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={6}
          className="border border-3 rounded-3"
        >
          <img
            src="https://i.ibb.co/KyLk5WZ/Sustainable-Development-Goals.webp"
            className="w-100"
            alt="SDG"
          />
        </Col>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={6}
          className="d-flex align-items-center justify-content-center border border-3 rounded-3"
        >
          <img
            src="https://i.ibb.co/fpSjWvx/Sustainable-Development-Goal-6-sm-md.webp"
            className="w-100 d-block d-lg-none"
            alt="Sustainable Developmet Goals"
          />
          <Carousel fade className="d-none d-lg-block">
            <Carousel.Item>
              <h1
                className="fw-bold"
                style={{
                  fontSize: "50px",
                  color: "#007D8A",
                }}
              >
                OUR MISSION
              </h1>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="https://i.ibb.co/Xx5DYnd/Sustainable-Development-Goal-6.webp"
                alt="Second slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <h3
                className="fw-bold"
                style={{
                  fontSize: "30px",
                  color: "#007D8A",
                }}
              >
                We will implement "SDGs 6th Main Agenda"
              </h3>
              <h1
                className="fw-bold"
                style={{
                  fontSize: "50px",
                  color: "#007D8A",
                }}
              >
                'Clean Water and Sanitation'.
              </h1>
            </Carousel.Item>
          </Carousel>
        </Col>
      </Row>
    </Container>
  );
};

export default HomeSDGslider;
