import React from "react";
import ReactPlayer from "react-player";
import { Col, Container, Row } from "react-bootstrap";
import "./videoGallery.css";

const VideoGallery = () => {
  return (
    <Container
      fluid
      className="activitiesContainer px-0 videoContainer"
      style={{
        marginTop: "50px",
        marginBottom: "50px",
        paddingBottom: "80px",
        paddingTop: "150px",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          padding: "50px 0 100px 0",
        }}
      >
        <Container>
          <h1
            className="fw-bold"
            style={{ fontSize: "50px", color: "#007D8A", marginBottom: "30px" }}
          >
            Video Gallery
          </h1>
        </Container>
        <Row>
          <Col
            xs={12}
            sm={12}
            md={{ span: 10, offset: 1 }}
            lg={{ span: 6, offset: 0 }}
            xl={{ span: 4, offset: 0 }}
          >
            <ReactPlayer
              url="https://youtu.be/_GZp7zaA0EI"
              controls={true}
              light={true}
              width="100%"
              style={{ background: "#000000" }}
            />
            <h5 className="py-3 fw-bold fs-5" style={{ color: "#007D8A" }}>
              <em>About O2&B</em>
            </h5>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={{ span: 10, offset: 1 }}
            lg={{ span: 6, offset: 0 }}
            xl={{ span: 4, offset: 0 }}
          >
            <ReactPlayer
              url="https://player.vimeo.com/video/721694325?h=23395385ba"
              controls={true}
              light={true}
              width="100%"
              style={{ background: "#000000" }}
            />
            <h5 className="py-3 fw-bold fs-5" style={{ color: "#007D8A" }}>
              <em>O2&B Motion Picture</em>
            </h5>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={{ span: 10, offset: 1 }}
            lg={{ span: 6, offset: 0 }}
            xl={{ span: 4, offset: 0 }}
          >
            <ReactPlayer
              url="https://youtu.be/yLaGECVMSfQ"
              controls={true}
              light={true}
              width="100%"
              style={{ background: "#000000" }}
            />
            <h5 className="py-3 fw-bold fs-5" style={{ color: "#007D8A" }}>
              <em>O2&B YouTube</em>
            </h5>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default VideoGallery;
