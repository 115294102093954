import React from 'react';
import { Container } from 'react-bootstrap';
import projectMap from "../../../image/about/map-117174_1280.webp"

const ProjectMap = () => {
    return (
        <Container className='py-5 my-5'>
            <h1 className="fs-1 py-3" style={{ color: "#022a58" }}>
            Our possibilities are limitless...
          </h1>
            <h5 className="fs-5" style={{ color: "#022a58" }}>
            We perform activities in many countries throughout the world. 
          </h5>
          <img src={projectMap} className="w-100 my-5 border border-3" alt=""/>
        </Container> 
    );
};

export default ProjectMap;