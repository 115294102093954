import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const Products = () => {
  return (
    <Container
      fluid
      className="px-0"
      style={{
        marginTop: "150px",
        marginBottom: "50px",
        overflow: "hidden",
      }}
    >
      <Container>
        <h1
          className="fw-bold"
          style={{ fontSize: "50px", color: "#007D8A", marginBottom: "30px" }}
        >
          Our Technological Solutions for Bangladesh
        </h1>
        <article className="mt-5" style={{ color: "#4e4e4e" }}>
          <h5 className="lh-lg">
            We want to establish an environmentally friendly and scalable
            solution for drinking water quality by reducing energy usage and
            cost, which will be easy to install and maintain. So these are our
            initial solution for Bangladesh.
          </h5>{" "}
        </article>
      </Container>
      <Row className="mt-5 pt-5">
        <Col xs={12} sm={12} md={12} lg={6} className="px-0">
          <img
            src="https://i.ibb.co/yPmjsSm/desert-roadtrip-designify.webp"
            className="w-100"
            alt="purifier10ton"
          />
        </Col>
        <Col xs={12} sm={12} md={12} lg={6} className="px-0">
          <img
            src="https://i.ibb.co/7bMTcXX/mountain-roadtrip-designify.webp"
            className="w-100"
            alt="purifier10ton"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Products;
