import React from "react";
import { Container } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "../../../hook/useAuth";
import TanzaniaBanner from "../TazaniaBanner/TanzaniaBanner";

const AdminLoginTz = () => {
  const { signInWithPassword, error, setError, setIsLoading, setUser } =
    useAuth();
  const { register, handleSubmit, reset } = useForm();
  //   const onSubmit = (data) => {
  //     setError("");
  //     signInWithPassword(data.email, data.password);
  //     reset();
  //     console.log(user);
  //   };

  let location = useLocation();
  let redriect_uri = location.state?.from || "/tz";
  const navigate = useNavigate();

  const handlePasswordLogin = (data) => {
    setError("");
    signInWithPassword(data.email, data.password)
      .then((userCredential) => {
        navigate(redriect_uri);
        setUser(userCredential.user);
        // ...
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => setIsLoading(false));
    reset();
  };

  return (
    <div>
      <TanzaniaBanner />
      <Container className="my-5 py-5">
        <h1
          className="fw-bold lh-lg"
          style={{ fontSize: "40px", color: "#779d24", marginBottom: "10px" }}
        >
          Admin Sign In
        </h1>
        <h6 className="fw-bold text-danger fs-6">
          {error ? "Wrong Email or, Password! Please try again." : ""}
        </h6>
        <form onSubmit={handleSubmit(handlePasswordLogin)}>
          <input
            type="email"
            placeholder="Enter Mail Address"
            {...register("email", { required: true, maxLength: 100 })}
            className="fs-5 px-3 py-2 my-4 d-block w-100 border border-info border-3 rounded-3"
          />
          <input
            type="password"
            placeholder="Enter Password"
            {...register("password", { required: true, maxLength: 100 })}
            className="fs-5 px-3 py-2 my-4 d-block w-100 border border-info border-3 rounded-3"
          />
          <input
            value="Sign In"
            className="px-3 py-2 fs-5 bg-info border-0 rounded-pill"
            type="submit"
          />
        </form>
      </Container>
    </div>
  );
};

export default AdminLoginTz;
