import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "../Modal1/Modal1.css";

const Modal2 = () => {
  const values = [true];
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }

  return (
    <div className="">
      {values.map((v, idx) => (
        <div
          key={idx}
          className="mainImg position-relative overflow-hidden"
          onClick={() => handleShow(v)}
        >
          <img
            src="https://i.ibb.co/vLD0nKH/img2.webp"
            className="w-100"
            alt="Bangladesh Water Board"
          />
          <div className="position-absolute h-100 w-100 top-0 start-0 imgOverlay">
            <div
              className="h-100 w-100 d-flex align-items-center justify-content-center"
              style={{ background: "#00aeefc2", bottom: "50px" }}
            >
              <h3 className="fw-bold fs-3 text-dark">
                <i className="fas fa-eye"></i> <br></br>
                Click to view full size image.
              </h3>
            </div>
          </div>
        </div>
      ))}
      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            O<sub>2</sub>
            <span className="fs-5">&</span>B Bangladesh authorities have been
            visiting Chandpur with PSL authorities.
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src="https://i.ibb.co/5WwZ1PV/img2.webp"
            className="w-100"
            alt="Bangladesh Water Board"
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Modal2;
