import React from "react";
import Banner from "../Banner/Banner";
import Goal from "../Goal/Goal";
import HomeSDGslider from "../HomeSDGslider/HomeSDGslider";
import LiveData from "../LiveData/LiveData";
import Partners from "../Partners/Partners";
import Qualities from "../Qualities/Qualities";
import SolutionAttributes from "../SolutionAttributes/SolutionAttributes";
import VideoGallery from "../VideoGallery/VideoGallery";

const Home = () => {
  return (
    <div>
      <Banner />
      <LiveData />
      <Goal />
      <HomeSDGslider />
      <Qualities />
      <SolutionAttributes />
      <Partners />
      <VideoGallery />
    </div>
  );
};

export default Home;
