import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";

const MilestoneDetailTZ = () => {
  const { id } = useParams();
  const [milestone, setMilestone] = useState({});
  useEffect(() => {
    fetch(`https://o2nb-global-server.onrender.com/prjMilestone/${id}`)
      .then((res) => res.json())
      .then((data) => setMilestone(data));
  }, [id]);
  return (
    <Container style={{ marginTop: "50px", marginBottom: "50px" }}>
      {milestone?.title ? (
        <div>
          <h1
            className="fw-bold"
            style={{ fontSize: "40px", color: "#779d24", marginBottom: "10px" }}
          >
            {milestone?.title}
          </h1>
          <img
            src={milestone?.thumbnail}
            className="w-100 py-5"
            style={{ borderRadius: "20px" }}
            alt="ProjectImage"
          />
          <h5
            style={{ fontSize: "20px", color: "#779d24", marginBottom: "30px" }}
          >
            {milestone?.details}
          </h5>
          {milestone?.img1 ? (
            <>
              <img
                src={milestone?.img1}
                className="w-100 pt-5"
                style={{ borderRadius: "20px" }}
                alt="ProjectImage"
              />
              <p className="pt-2 pb-5 fs-6 text-secondary">
                {milestone?.img1Subtitle}
              </p>
            </>
          ) : (
            ""
          )}
          {milestone?.img2 ? (
            <>
              <img
                src={milestone?.img2}
                className="w-100 pt-5"
                style={{ borderRadius: "20px" }}
                alt="ProjectImage"
              />
              <p className="pt-2 pb-5 fs-6 text-secondary">
                {milestone?.img2Subtitle}
              </p>
            </>
          ) : (
            ""
          )}
          {milestone?.img3 ? (
            <>
              <img
                src={milestone?.img3}
                className="w-100 pt-5"
                style={{ borderRadius: "20px" }}
                alt="ProjectImage"
              />
              <p className="pt-2 pb-5 fs-6 text-secondary">
                {milestone?.img3Subtitle}
              </p>
            </>
          ) : (
            ""
          )}
        </div>
      ) : (
        <Spinner animation="border" variant="success" />
      )}
    </Container>
  );
};

export default MilestoneDetailTZ;
