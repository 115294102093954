import React from "react";
import { Button, Col, Row } from "react-bootstrap";

const TzAdminManageCard = ({ milestone, handleDeleteTz }) => {
  return (
    <div className="card mb-3" style={{ maxWidth: "540px" }}>
      <Row className="g-0">
        <Col md={4}>
          <img
            src={milestone?.thumbnail}
            className="img-fluid rounded-start"
            alt="ProjectImage"
          />
        </Col>
        <Col md={8}>
          <div className="card-body">
            <h5 className="card-title">{milestone.title}</h5>
            <Button
              onClick={() => {
                handleDeleteTz(milestone?._id);
              }}
              variant="danger"
            >
              Delete
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TzAdminManageCard;
