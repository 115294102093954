const firebaseConfig = {
  apiKey: "AIzaSyCa6bWb6lMJai6GDFj1kO_zUvEapCfI9x4",
  authDomain: "o2nb-e757e.firebaseapp.com",
  projectId: "o2nb-e757e",
  storageBucket: "o2nb-e757e.appspot.com",
  messagingSenderId: "615692794310",
  appId: "1:615692794310:web:4b144664da9634cc4a5ac7",
};

export default firebaseConfig;
