import React from "react";
import { Container } from "react-bootstrap";
import ActivityContainer from "./ActivityContainer/ActivityContainer";

const ProjectActivities = () => {
  return (
    <Container
      fluid
      className="activitiesContainer px-0"
      style={{
        marginTop: "50px",
        marginBottom: "50px",
      }}
    >
      <div
        style={{
          padding: "50px 0 100px 0",
        }}
      >
        <Container>
          <h1
            className="fw-bold"
            style={{ fontSize: "50px", color: "#007D8A", marginBottom: "30px" }}
          >
            "Drinking Water Purification" Project Activities.
          </h1>

          <article className="mt-5" style={{ color: "#4e4e4e" }}>
            <h5 className="lh-lg">
              Recently the O2&B authorities have gone to a visit in the Chandpur
              district of Bangladesh. They have gone to many important locations
              and sites to examine and survey the quality of water and also the
              need for pure water. With the O2&B authorities, PSL authorities
              were also at the site to jointly examine the necessary
              improvements of water.
            </h5>{" "}
          </article>
        </Container>
        <ActivityContainer />
      </div>
    </Container>
  );
};

export default ProjectActivities;
