import React from 'react';
import { Container } from 'react-bootstrap';
import majorBaBanner from "../../../image/about/majorBusinessBanner.webp";

const MajorBA = () => {
    return (
        <Container fluid className="px-0 py-5">
      <img src={majorBaBanner} className="w-100" alt="O2&B Korea" />
      <h5 className="py-3 px-5 fs-5 text-end text-secondary text-capitalize">
        We displayed excellent technological capability and performance.
      </h5>
      <Container>
        <h1 className="fs-1 text-start" style={{ color: "#022a58" }}>
          Major Business Area
        </h1>
        <h6 className="py-3 px-5 lh-lg fs-6 text-start text-secondary">
          a) water purification technology for an advanced water purifying
          plant, village drinking water system, and movable water purifying
          facility, <br /> b) Sewer and sewage treatment and waste water
          treatment technology for advanced sewer treatment plant, waste water
          traetment facility : large and midium scale sewer treatment facility,
          industrial complex waste water treatment system and specified waste
          water traetment facility for hospitals and and slaughterhouses. We
          displayed Excellent Technological capability and performances.
        </h6>
      </Container>
    </Container>
    );
};

export default MajorBA;