import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Modal1 from "./Modal1/Modal1";
import Modal2 from "./Modal2/Modal2";
import Modal3 from "./Modal3/Modal3";
import Modal4 from "./Modal4/Modal4";
import Modal5 from "./Modal5/Modal5";
import Modal6 from "./Modal6/Modal6";
import Modal7 from "./Modal7/Modal7";
import Modal8 from "./Modal8/Modal8";

const ActivityContainer = () => {
  return (
    <Container fluid className="pt-5">
      <Row className="overflow-hidden">
        <Col xs={12} sm={12} md={6} lg={3} className="px-0">
          <Modal1 />
          <Modal4 />
        </Col>
        <Col xs={12} sm={12} md={6} lg={3} className="px-0">
          <Modal5 />
          <Modal2 />
        </Col>
        <Col xs={12} sm={12} md={6} lg={3} className="px-0">
          <Modal3 />
          <Modal6 />
        </Col>
        <Col xs={12} sm={12} md={6} lg={3} className="px-0">
          <Modal7 />
          <Modal8 />
        </Col>
      </Row>
    </Container>
  );
};

export default ActivityContainer;
