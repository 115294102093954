import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import net from "../../../image/about/NETcertifacate.webp";

const NewTech = () => {
  return (
    <Container className="my-5 py-5">
      <Row>
        <Col
          xs={12}
          sm={12}
          md={{ offset: 2, span: 8 }}
          lg={{ offset: 0, span: 6 }}
        >
          <img src={net} alt="license" />
        </Col>
        <Col
          xs={12}
          sm={12}
          md={{ offset: 2, span: 8 }}
          lg={{ offset: 0, span: 6 }}
        >
          <h1 className="fs-1 text-start" style={{ color: "#022a58" }}>
            New Excellent Technology
          </h1>
          <h6 className="py-3 px-5 lh-lg fs-6 text-start text-secondary">
            - Tap water treatment: New Technology No. 605, No. 1472 <br />-
            Sewer treatment: Environmental New Technology No.10, No.11
          </h6>
        </Col>
      </Row>
    </Container>
  );
};

export default NewTech;
