import React from "react";
import { Container } from "react-bootstrap";
import useMilestonesTZ from "../../../hook/useMilestonesTZ";
import MilestoneCardTZ from "./MilestoneCardTZ/MilestoneCardTZ";

const MilestoneProjectTZ = () => {
  const { milestones } = useMilestonesTZ();

  return (
    <div>
      <Container
        fluid
        className="px-0 position-relative"
        style={{
          background: "#779d24",
          marginTop: "150px",
          marginBottom: "50px",
        }}
      >
        <Container className="py-4">
          <div className="py-5 my-5 text-light">
            <h1 className="fs-1 lh-lg">PROJECT MILESOTNES</h1>
            <h3 className="text-capitalize">
              The people of Tanzania are facing many problems with their
              drinking water. Drinking pure water is essential for maintaining
              good health. Water makes up a large part of our bodies and is
              necessary for many of the functions that keep us alive. That's why
              we have established many important projects in Tanzania. Our
              innovative water-purifying technologies have brought about
              tremendous advancements in the drinking water condition at our
              project sites in Tanzania. These advances have allowed for the
              creation of new products and services, as well as the improvement
              of existing ones.
            </h3>
          </div>
        </Container>
        <div
          className="position-absolute"
          style={{
            width: "60px",
            height: "60px",
            background: "#779d24",
            bottom: "-15px",
            left: "48%",
            transform: "rotate(45deg)",
          }}
        ></div>
      </Container>
      <Container className="d-flex flex-wrap justify-content-around">
        {milestones.map((milestone) => (
          <MilestoneCardTZ key={milestone?._id} milestone={milestone} />
        ))}
      </Container>
    </div>
  );
};

export default MilestoneProjectTZ;
