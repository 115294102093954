import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./Pages/Home/Home/Home";
import About from "./Pages/About/About/About";
import { useEffect } from "react";
import { Container } from "react-bootstrap";
import TanzaniaHome from "./Pages/Tanzania/TanzaniaHome/TanzaniaHome";
import FormMsgMngrTZ from "./Pages/Tanzania/MsgMngrTZ/FormMsgMngrTZ/FormMsgMngrTZ";
import TanzaniaAdminDashboard from "./Pages/Tanzania/TanzaniaAdminDashboard/TanzaniaAdminDashboard";
// import TzAdminUpInfo from "./Pages/Tanzania/TanzaniaAdminDashboard/TzAdminUpInfo/TzAdminUpInfo";
import Navigation from "./Pages/Shared/Navigation/Navigation";
import Footer from "./Pages/Shared/Footer/Footer/Footer";
import MilestoneDetailTZ from "./Pages/Tanzania/MilestoneProjectTZ/MilestoneDetailTZ/MilestoneDetailTZ";
import TzAdminManage from "./Pages/Tanzania/TanzaniaAdminDashboard/TzAdminManage/TzAdminManage";
// import TzAdminRcvMsg from "./Pages/Tanzania/TanzaniaAdminDashboard/TzAdminRcvMsg/TzAdminRcvMsg";
import AuthProvider from "./Context/AuthProvider";
import AdminLoginTz from "./Pages/Tanzania/AdminLoginTz/AdminLoginTz";
import PrivateRoute from "./PrivateRoute/PrivateRoute";
import BangladeshHome from "./Pages/Bangladesh/BangladeshHome/BangladeshHome";

function App() {
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        layout:
          window.google.translate.TranslateElement.InlineLayout.HORIZONTAL,
      },
      "google_translate_element"
    );
  };
  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  return (
    <Container fluid className="px-0 overflow-hidden">
      <Container id="google_translate_element"></Container>
      <div className="App">
        <AuthProvider>
          <BrowserRouter>
            <Navigation />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/home" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/tz" element={<TanzaniaHome />} />
              <Route path="/bd" element={<BangladeshHome />} />
              <Route path="/tzForm" element={<FormMsgMngrTZ />} />
              <Route path="/tz/admin" element={<AdminLoginTz />} />
              <Route
                path="/eTZofhAdminDB"
                element={
                  <PrivateRoute>
                    <TzAdminManage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/eTZofhAdminDBuPloAD"
                element={
                  <PrivateRoute>
                    <TanzaniaAdminDashboard />
                  </PrivateRoute>
                }
              />
              {/* <Route path="/eTZofhAdminDBrcvMsg" element={<TzAdminRcvMsg />} /> */}
              <Route path="/tz/:id" element={<MilestoneDetailTZ />} />
            </Routes>
            <Footer />
          </BrowserRouter>
        </AuthProvider>
      </div>
    </Container>
  );
}

export default App;
