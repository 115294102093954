import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ceoMsgBanner from "../../../image/about/ceoBanner.webp";
import ceo from "../../../image/about/ceo.webp";

const CeoMsg = () => {
    return (
        <Container fluid className="px-0 py-5">
      <img src={ceoMsgBanner} className="w-100" alt="O2&B Korea" />
      <Container>
        <Row className="my-5">
          <Col xs={12} sm={12} md={4} lg={2} style={{ background: "#dddddd" }}>
            <h3 className="fs-3 lh-lg" style={{ color: "#022a58" }}>
              CEO Message
            </h3>
          </Col>
          <Col xs={12} sm={12} md={8} lg={10}>
            <section>
              <img src={ceo} alt="CEO of O2&B" />
              <h6 className="fs-6 pt-2 text-secondary">Seung Hyun Bahn</h6>
              <h6 className="fs-6 text-secondary">CEO</h6>
            </section>
            <h3 className="fs-3 text-start" style={{ color: "#022a58" }}>
              0<sub>2</sub>&B was founded in 2007 with the corporate philosophy
              of pursuing a symbiotic relationship between nature and humans.
            </h3>
            <h6 className="py-3 px-5 lh-lg fs-6 text-start text-secondary">
              02&B is an advanced design company that shows a new paradigm of
              total design that suggests environment design, landscape
              architectural design, and working design via investment for human
              resource development, a realistic design based on field experience
              and continuous self-development and innovation. 02&B is thankful
              for all of our partners' unfailing support,and trust. We will
              persistantly strive for the satistfaction of every one of our
              customers without arrogance, but with sincerety and integrity.
              02&B will constiatntly investigate, innovate, and challenge
              ourselves. We will administrate with transparency and right-minded
              while staying harmonious and true to our business partners. We
              will actively invest the new design workers, always endeavoring
              ourselves for the bestest result. The future belongs to the people
              and businesses who dream and strive for it. 02&B will contiunually
              emphasize more sustainable growth and harmony in society,
              environment and economy, presenting brighter future, better
              tomorrow.
              <br /> Thank You!
            </h6>
          </Col>
        </Row>
      </Container>
    </Container>
    );
};

export default CeoMsg;