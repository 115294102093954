import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const Partner = () => {
  return (
    <Container style={{ marginTop: "100px", marginBottom: "100px" }}>
      <h1
        className="fw-bold"
        style={{ fontSize: "40px", color: "#e0457b", marginBottom: "30px" }}
      >
        "Drinking Water Purification" Project Implementation Assistant.
      </h1>

      <Row>
        <Col xs={12} sm={12} md={6} lg={4}>
          <div className="p-5 border border-3 mt-5 mb-3">
            <img
              src="https://i.ibb.co/wK3kKB8/NEW-psl-logo-w-OUT-text-1.webp"
              className="w-100"
              alt="psl-logo"
            />
          </div>

          <a href="https://www.psldhaka.net/" rel="noreferrer" target="_blank">
            <h1 style={{ color: "#333333" }}>
              {" "}
              Prokaushali Sangsad Ltd. (PSL)
            </h1>
          </a>
        </Col>
        <Col xs={12} sm={12} md={6} lg={8}>
          <article className="text-start mt-5" style={{ color: "#4e4e4e" }}>
            <h5>
              Prokaushali Sangsad Ltd. (PSL) was established on 7th July 1969,
              as an Engineering Consulting firm to offer services in various
              fields of development activity in the country. The company was
              registered on 7th July 1969 with the Registrar of Joint Stocks and
              Company. Since 1997, PSL has focused its activities in the areas
              of Rural and Renewable Energy, Energy Efficiency, Environment, and
              capacity development. PSL has contributed its professional
              services to several pioneering projects in renewable energy in
              Bangladesh.
            </h5>

            <h5 className="pt-3">
              In the past decades, PSL has rendered consultancy services to
              Government departments, autonomous bodies, corporations, and
              Non-Government Organizations (NGOs). It has successfully
              undertaken projects funded by national and international
              development partners, such as GIZ, World Bank, ESMAP, KfW, UNDP,
              ADB, NORAD, and private establishments.
            </h5>

            <h5 className="pt-3">
              PSL was awarded the prestigious “Ashden Award for Sustainable
              Energy” from the UK in 2005. The company is very active in
              technology transfer in the energy sector for the benefit of the
              un-electrified rural population.
            </h5>
          </article>
        </Col>
      </Row>
    </Container>
  );
};

export default Partner;
