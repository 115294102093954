import React, { useEffect, useState } from "react";
import { Button, Container, Dropdown, Nav, Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import useAuth from "../../../hook/useAuth";
import logo from "../../../image/site_logo.webp";
import "./Navigation.css";

const Navigation = () => {
  const { user, logOut } = useAuth();
  // let activeStyle = {
  //   backgroundColor: "#009edb",
  //   color: "#ffffff",
  //   transition: "ease-in-out 0.5s",
  // };

  // let nonActiveStyle = {
  //   fontSize: "18px",
  //   color: "#000000",
  //   padding: "10px",
  //   textDecoration: "none",
  // };
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    fetch("./countries.json")
      .then((res) => res.json())
      .then((data) => setCountries(data));
  }, []);
  return (
    <div className="navDiv">
      <Navbar
        // fixed="top"
        bg="light"
        expand="lg"
        // className="mt-4"
        style={{ borderBottom: "5px solid #009edb", marginTop: "15px" }}
      >
        <Container>
          <Navbar.Brand className="text-start">
            <NavLink to="/">
              <img src={logo} className="w-50" alt="o2nbLogo" />
            </NavLink>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto ">
              <Dropdown className="mx-3">
                <Dropdown.Toggle
                  variant="light"
                  id="dropdown-basic"
                  style={{ border: "3px solid rgb(52 170 244)" }}
                >
                  <h6 className="fw-light px-2">Country</h6>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <div className="d-flex flex-column">
                    {countries.map(({ countryCode, country, countryURL }) => (
                      <NavLink
                        key={countryCode}
                        to={countryURL}
                        className="d-flex text-decoration-none fs-5 px-5 pt-3 border-bottom"
                      >
                        <span
                          className={`flag-icon flag-icon-${countryCode} me-2`}
                        ></span>
                        {country}
                      </NavLink>
                    ))}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
              <NavLink
                to="/"
                className="text-primary p-3 fs-5 text-decoration-none menuItem"
              >
                <h5 className="p-2 rounded-3">Home</h5>
              </NavLink>
              <NavLink
                to="/about"
                className="text-primary p-3 fs-5 text-decoration-none menuItem"
              >
                <h5 className="p-2 rounded-3">About Us</h5>
              </NavLink>
              <NavLink
                to="/eTZofhAdminDB"
                className="text-primary p-3 fs-5 text-decoration-none menuItem"
              >
                <h5 className="p-2 rounded-3">Manage</h5>
              </NavLink>
              {user?.email ? (
                <Button
                  onClick={logOut}
                  variant="outline-danger"
                  className="fs-5 text-decoration-none border-0"
                >
                  Log Out
                </Button>
              ) : (
                ""
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Navigation;
