import React from "react";
import { Card } from "react-bootstrap";

const PartnerCard = ({ partner }) => {
  return (
    <a
      href={partner.partnerLink}
      className="text-decoration-none"
      rel="noreferrer"
      target="_blank"
    >
      <Card style={{ width: "18rem" }} className="my-3 border-0 bg-transparent">
        <Card.Img variant="top" className="p-3" src={partner.partnerImg} />
      </Card>
    </a>
  );
};

export default PartnerCard;
