import React from "react";
import MilestoneProjectTZ from "../MilestoneProjectTZ/MilestoneProjectTZ";
// import MsgMngrTz from "../MsgMngrTZ/MsgMngrTZ/MsgMngrTz";
import TanzaniaBanner from "../TazaniaBanner/TanzaniaBanner";

const TanzaniaHome = () => {
  return (
    <div>
      <TanzaniaBanner />
      <MilestoneProjectTZ />
      {/* <MsgMngrTz /> */}
    </div>
  );
};

export default TanzaniaHome;
